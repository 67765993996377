// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// $soft-background-color: #fbfbfb !default;
$soft-background-color: #ffffff !default; // Testing
$font-color: #67748e !default;
$h-color: #344767 !default;

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// fusv-enable

$blue: #63b3ed !default;
$indigo: #596cff !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #f56565 !default;
$orange: #fd7e14 !default;
$yellow: #fbd38d !default;
$green: #81e6d9 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

$primary: #cb0c9f !default;
$secondary: #8392ab !default;
$info: #17c1e8 !default;
$success: #82d616 !default;
$warning: #fbcf33 !default;
$danger: #ea0606 !default;
$light: $gray-200 !default;
$dark: $h-color !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
) !default;
// scss-docs-end theme-colors-map

// Gradient colors
$primary-gradient: #7928ca !default;
$primary-gradient-state: #ff0080 !default;

$secondary-gradient: #627594 !default;
$secondary-gradient-state: #a8b8d8 !default;

$info-gradient: #2152ff !default;
$info-gradient-state: #21d4fd !default;

$success-gradient: #17ad37 !default;
$success-gradient-state: #98ec2d !default;

$danger-gradient: #ea0606 !default;
$danger-gradient-state: #ff667c !default;

$warning-gradient: #f53939 !default;
$warning-gradient-state: #fbcf33 !default;

$dark-gradient: #141727 !default;
$dark-gradient-state: #3a416f !default;

$light-gradient: #ced4da !default;
$light-gradient-state: #ebeff4 !default;

$dark-gradient-dark: #323a54 !default;
$dark-gradient-state-dark: #1a2035 !default;

// Gradient Colors map
$theme-gradient-colors: (
  "primary": (
    $primary-gradient,
    $primary-gradient-state,
  ),
  "secondary": (
    $secondary-gradient,
    $secondary-gradient-state,
  ),
  "success": (
    $success-gradient,
    $success-gradient-state,
  ),
  "info": (
    $info-gradient,
    $info-gradient-state,
  ),
  "warning": (
    $warning-gradient,
    $warning-gradient-state,
  ),
  "danger": (
    $danger-gradient,
    $danger-gradient-state,
  ),
  "light": (
    $light-gradient,
    $light-gradient-state,
  ),
  "dark": (
    $dark-gradient,
    $dark-gradient-state,
  ),
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 8) !default;
$blue-200: tint-color($blue, 6) !default;
$blue-300: tint-color($blue, 4) !default;
$blue-400: tint-color($blue, 2) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 2) !default;
$blue-700: shade-color($blue, 4) !default;
$blue-800: shade-color($blue, 6) !default;
$blue-900: shade-color($blue, 8) !default;

$indigo-100: tint-color($indigo, 8) !default;
$indigo-200: tint-color($indigo, 6) !default;
$indigo-300: tint-color($indigo, 4) !default;
$indigo-400: tint-color($indigo, 2) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 2) !default;
$indigo-700: shade-color($indigo, 4) !default;
$indigo-800: shade-color($indigo, 6) !default;
$indigo-900: shade-color($indigo, 8) !default;

$purple-100: tint-color($purple, 8) !default;
$purple-200: tint-color($purple, 6) !default;
$purple-300: tint-color($purple, 4) !default;
$purple-400: tint-color($purple, 2) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 2) !default;
$purple-700: shade-color($purple, 4) !default;
$purple-800: shade-color($purple, 6) !default;
$purple-900: shade-color($purple, 8) !default;

$pink-100: tint-color($pink, 8) !default;
$pink-200: tint-color($pink, 6) !default;
$pink-300: tint-color($pink, 4) !default;
$pink-400: tint-color($pink, 2) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 2) !default;
$pink-700: shade-color($pink, 4) !default;
$pink-800: shade-color($pink, 6) !default;
$pink-900: shade-color($pink, 8) !default;

$red-100: tint-color($red, 8) !default;
$red-200: tint-color($red, 6) !default;
$red-300: tint-color($red, 4) !default;
$red-400: tint-color($red, 2) !default;
$red-500: $red !default;
$red-600: shade-color($red, 2) !default;
$red-700: shade-color($red, 4) !default;
$red-800: shade-color($red, 6) !default;
$red-900: shade-color($red, 8) !default;

$orange-100: tint-color($orange, 8) !default;
$orange-200: tint-color($orange, 6) !default;
$orange-300: tint-color($orange, 4) !default;
$orange-400: tint-color($orange, 2) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 2) !default;
$orange-700: shade-color($orange, 4) !default;
$orange-800: shade-color($orange, 6) !default;
$orange-900: shade-color($orange, 8) !default;

$yellow-100: tint-color($yellow, 8) !default;
$yellow-200: tint-color($yellow, 6) !default;
$yellow-300: tint-color($yellow, 4) !default;
$yellow-400: tint-color($yellow, 2) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 2) !default;
$yellow-700: shade-color($yellow, 4) !default;
$yellow-800: shade-color($yellow, 6) !default;
$yellow-900: shade-color($yellow, 8) !default;

$green-100: tint-color($green, 8) !default;
$green-200: tint-color($green, 6) !default;
$green-300: tint-color($green, 4) !default;
$green-400: tint-color($green, 2) !default;
$green-500: $green !default;
$green-600: shade-color($green, 2) !default;
$green-700: shade-color($green, 4) !default;
$green-800: shade-color($green, 6) !default;
$green-900: shade-color($green, 8) !default;

$teal-100: tint-color($teal, 8) !default;
$teal-200: tint-color($teal, 6) !default;
$teal-300: tint-color($teal, 4) !default;
$teal-400: tint-color($teal, 2) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 2) !default;
$teal-700: shade-color($teal, 4) !default;
$teal-800: shade-color($teal, 6) !default;
$teal-900: shade-color($teal, 8) !default;

$cyan-100: tint-color($cyan, 8) !default;
$cyan-200: tint-color($cyan, 6) !default;
$cyan-300: tint-color($cyan, 4) !default;
$cyan-400: tint-color($cyan, 2) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 2) !default;
$cyan-700: shade-color($cyan, 4) !default;
$cyan-800: shade-color($cyan, 6) !default;
$cyan-900: shade-color($cyan, 8) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 10,
  10: $spacer * 12,
  11: $spacer * 14,
  12: $spacer * 16,
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

$position-values: (
  0: 0,
  1: 1%,
  2: 2%,
  3: 3%,
  4: 4%,
  5: 5%,
  6: 6%,
  7: 7%,
  8: 8%,
  9: 9%,
  10: 10%,
  50: 50%,
  100: 100%,
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $font-color !default;
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius-xs: 0.125rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-md: 0.5rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 1.5rem !default;
$border-radius-section: 10rem !default;

$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$rounded-pill: 50rem !default;

$box-shadow-xs: 0 2px 9px -5px rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12),
  0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07) !default;
$box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !default;
$box-shadow-lg: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06) !default;
$box-shadow-xl: 0 23px 45px -11px rgba(20, 20, 20, 0.25) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Open Sans" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--bs-font-sans-serif) !default;
$font-family-code: var(--bs-font-monospace) !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xxs: $font-size-base * 0.65 !default;
$font-size-xs: $font-size-base * 0.75 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.125 !default;
$font-size-xl: $font-size-base * 1.25 !default;
$font-size-2xl: $font-size-base * 1.5 !default;
$font-size-3xl: $font-size-base * 1.875 !default;
$font-size-4xl: $font-size-base * 2 !default;
$font-size-5xl: $font-size-base * 2.25 !default;
$font-size-6xl: $font-size-base * 3 !default;
$font-size-7xl: $font-size-base * 3.75 !default;
$font-size-8xl: $font-size-base * 4 !default;
$font-size-9xl: $font-size-base * 5 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$font-weight-base: $font-weight-normal !default;

$h1-font-weight: $font-weight-bold !default;
$h2-font-weight: $font-weight-bold !default;
$h3-font-weight: $font-weight-bold !default;
$h4-font-weight: $font-weight-bold !default;
$h5-font-weight: $font-weight-bold !default;
$h6-font-weight: $font-weight-bold !default;
$p-font-weight: $font-weight-normal !default;
$lead-font-weight: $font-weight-normal !default;
$text-sm-font-weight: $font-weight-normal !default;
$text-xs-font-weight: $font-weight-normal !default;

$line-height: 1.75rem !default;
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-line-height: 1.25 !default;
$h2-line-height: 1.3 !default;
$h3-line-height: 1.375 !default;
$h4-line-height: 1.375 !default;
$h5-line-height: 1.375 !default;
$h6-line-height: 1.625 !default;
$p-line-height: 1.6 !default;
$lead-line-height: 1.625 !default;
$text-sm-line-height: 1.5 !default;
$text-xs-line-height: 1.25 !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$lead-font-size: $font-size-base * 1.25 !default;

$text-sm-font-size: 0.875rem !default;
$text-xs-font-size: 0.75rem !default;
$p-font-size: 1rem !default;

$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.2 !default;
$headings-color: #344767 !default;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 0.875em !default;

$sub-sup-font-size: 0.75em !default;

$text-secondary: $font-color !default;
$text-muted: $gray-600 !default;

// text gradient
$text-gradient-bg-clip: text !default;
$text-gradient-text-fill: transparent !default;
$text-gradient-position: relative !default;
$text-gradient-zindex: 1 !default;
$text-gradient-bg-primary: linear-gradient(310deg, #7928ca, #ff0080) !default;
$text-gradient-bg-info: linear-gradient(310deg, #2152ff, #21d4fd) !default;
$text-gradient-bg-success: linear-gradient(310deg, #17ad37, #c1e823) !default;
$text-gradient-bg-warning: linear-gradient(310deg, #f53939, #fbcf33) !default;
$text-gradient-bg-danger: linear-gradient(310deg, #d60808, #ff6690) !default;
$text-gradient-bg-dark: linear-gradient(310deg, #141727, #3a416f) !default;

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-height: $border-width !default;
$hr-opacity: 0.25 !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

// Letter Spacing
$letter-wider: 0.05rem !default;
$letter-normal: 0rem !default;
$letter-tighter: -0.05rem !default;
$h1-letter-spacing: 0.1rem !default;
$a-letter-spacing: -0.025rem !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-head-spacer-y: 0.75rem !default;
$table-head-spacer-x: 1.5rem !default;
$table-head-font-size: 0.65rem !default;
$table-head-font-weight: $font-weight-bold !default;
$table-head-text-transform: capitalize !default;
$table-head-letter-spacing: 0px !default;
$table-head-bg: $gray-100 !default;
$table-head-color: $gray-600 !default;

$table-body-font-size: 0.8125rem !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-200 !default;

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-striped-order: odd !default;

$table-group-separator-color: currentColor !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-bg-scale: -80% !default;

$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: 1rem !default;
$input-btn-line-height: 1.4 !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: 0.75rem !default;

$input-btn-padding-y-lg: 0.75rem !default;
$input-btn-padding-x-lg: 0.75rem !default;
$input-btn-font-size-lg: 0.875rem !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0.75rem !default;
$btn-padding-x: 1.5rem !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $text-xs-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping
$btn-letter-spacing: -0.025rem !default; // Set to `nowrap` to prevent text wrapping

$btn-margin-bottom: 1rem !default;
$btn-padding-y-sm: 0.5rem !default;
$btn-padding-x-sm: 2rem !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: 0.875rem !default;
$btn-padding-x-lg: 4rem !default;
$btn-font-size-lg: 0.875rem !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-bolder !default;
$btn-box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11), 0 2px 4px -1px rgba(0, 0, 0, 0.07) !default;
$btn-box-shadow-values: 0 5px 8px 0 !default;
$btn-box-shadow-hover-values: 0 8px 10px 0 !default;
$btn-hover-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07) !default;
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-hover-opacity: 0.85 !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: none !default;
$btn-background-size: 150% !default;
$btn-hover-transform: scale(1.02) !default;
$btn-active-hover-transform: scale(1) !default;
$btn-background-position-x: 25% !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0.5rem !default;
$btn-border-radius-lg: 0.5rem !default;
$btn-border-radius-sm: 0.5rem !default;
$btn-border-rounded: 1.875rem !default;

$btn-icon-transition: all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3) !default;
$btn-icon-transform-right: translateX(5px) !default;
$btn-icon-transform-left: translateX(-5px) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius-md !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: all 0.15s ease-in !default;

$btn-just-icon-padding-x-sm: 0.3rem !default;
$btn-just-icon-padding-y-sm: 0.3rem !default;
$btn-just-icon-padding-x: 0.7rem !default;
$btn-just-icon-padding-y: 0.7rem !default;
$btn-just-icon-padding-x-lg: 1rem !default;
$btn-just-icon-padding-y-lg: 1rem !default;
$btn-just-icon-width: 2.375rem !default;
$btn-just-icon-height: $btn-just-icon-width !default;
$btn-just-icon-width-sm: 1.5875rem !default;
$btn-just-icon-height-sm: $btn-just-icon-width-sm !default;
$btn-just-icon-sm-font-size: 0.5rem !default;
$btn-just-icon-width-lg: 3.25rem !default;
$btn-just-icon-height-lg: $btn-just-icon-width-lg !default;
$btn-just-icon-lg-font-size: 1.2rem !default;
$btn-just-icon-lg-position: relative !default;
$btn-just-icon-lg-top: 2px !default;

// we've overwritten the default Bootstrap function
// for dynamically adding font color since the colors
// added by that function were not correctly set
$btn-primary-font-color: #fff !default;
$btn-secondary-font-color: #fff !default;
$btn-danger-font-color: #fff !default;
$btn-info-font-color: #fff !default;
$btn-success-font-color: #fff !default;
$btn-warning-font-color: #fff !default;
$btn-dark-font-color: #fff !default;
$btn-light-font-color: #3a416f !default;

$btn-font-colors: () !default;

$btn-font-colors: map-merge(
  (
    "primary": $btn-primary-font-color,
    "secondary": $btn-secondary-font-color,
    "danger": $btn-danger-font-color,
    "info": $btn-info-font-color,
    "success": $btn-success-font-color,
    "warning": $btn-warning-font-color,
    "dark": $btn-dark-font-color,
    "light": $btn-light-font-color,
  ),
  $btn-font-colors
);

// Forms

$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;

$form-label-margin-bottom: 0.5rem !default;
$form-label-margin-left: 0.25rem !default;
$form-label-font-size: 0.75rem !default;
$form-label-font-style: null !default;
$form-label-font-weight: 700 !default;
$form-label-color: $dark !default;

$input-padding-y: 0.5rem !default;
$input-padding-x: 0.75rem !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $font-size-sm !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: 1.4rem !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: 0.75rem !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: 0.875rem !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: null !default;

$input-color: $gray-700 !default;
$input-border-color: #d2d6da !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !default;

$input-border-radius: $border-radius-md !default;
$input-border-radius-sm: $border-radius-md !default;
$input-border-radius-lg: $border-radius-md !default;

$input-focus-bg: $white !default;
$input-focus-border-color: #e293d3 !default;
$input-focus-color: $input-color !default;
$input-focus-width: 2px !default;
$input-focus-box-shadow: 0 0 0 $input-focus-width #e9aede !default;

$input-placeholder-color: $gray-500 !default;
$input-plaintext-color: $dark !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: unset !default;
$input-height-inner-half: 1rem !default;
$input-height-inner-quarter: 0.75rem !default;

$input-height: unset !default;
$input-height-sm: unset !default;
$input-height-lg: unset !default;

$input-transition: box-shadow 0.15s ease, border-color 0.15s ease !default;

$form-check-input-width: 1.23em !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-left: $form-check-input-width + 0.45em !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-color: null !default;
$form-check-label-cursor: null !default;
$form-check-transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out,
  opacity 0.15s ease-out, box-shadow 0.15s ease-in-out !default;
$form-check-transition-time: 0.25s !default;

$form-check-input-active-filter: brightness(99%) !default;

$form-check-input-bg: $white !default;
$form-check-input-border: none !default;
$form-check-input-border-radius: 0.35rem !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: none !default;
$form-check-input-focus-box-shadow: none !default;

$form-check-input-checked-color: $white !default;
$form-check-input-checked-bg-color: transparent !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image: linear-gradient(310deg, $dark-gradient 0%, $dark-gradient-state 100%) !default;
$form-check-radio-checked-bg-image: $form-check-input-checked-bg-image !default;
$form-check-radio-after-width: 0.5rem !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-switch-color: rgba(0, 0, 0, 1) !default;
$form-switch-height: 1.2em !default;
$form-switch-width: 2.5rem !default;
$form-switch-check-after-width: 1rem !default;
$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: none !default;
$form-switch-border-radius: $form-switch-width !default;
$form-switch-translate-x-start: 1px !default;
$form-switch-translate-x-end: 21px !default;
$form-switch-round-box-shadow: $box-shadow !default;
$form-switch-transition: $form-check-transition !default;

$form-switch-focus-color: $form-switch-color !default;
$form-switch-focus-bg-image: $form-switch-bg-image !default;
$form-switch-checked-color: $white !default;
$form-switch-checked-bg-image: $form-switch-bg-image !default;
$form-switch-checked-bg-position: right center !default;

$form-check-inline-margin-right: 1rem !default;

$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $dark !default;
$input-group-addon-bg: $white !default;
$input-group-addon-border-color: $input-border-color !default;

$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-height: $input-height !default;
$form-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-disabled-color: $gray-600 !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-bg: $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-800 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-right: add(
  1em * 0.75,
  (2 * $form-select-padding-y * 0.75) + $form-select-padding-x + $form-select-indicator-padding
) !default;
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $border-radius-md !default;
$form-select-box-shadow: $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: $input-focus-box-shadow !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-height-sm: $input-height-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-height-lg: $input-height-lg !default;

$form-range-track-width: 100% !default;
$form-range-track-height: 0.5rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: $gray-300 !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow: $box-shadow-inset !default;

$form-range-thumb-width: 1rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$form-range-thumb-disabled-bg: $gray-500 !default;
$form-range-thumb-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

$form-file-height: $input-height !default;
$form-file-focus-border-color: $input-focus-border-color !default;
$form-file-focus-box-shadow: $input-focus-box-shadow !default;
$form-file-disabled-bg: $input-disabled-bg !default;
$form-file-disabled-border-color: $input-disabled-border-color !default;

$form-file-padding-y: $input-padding-y !default;
$form-file-padding-x: $input-padding-x !default;
$form-file-line-height: $input-line-height !default;
$form-file-font-family: $input-font-family !default;
$form-file-font-weight: $input-font-weight !default;
$form-file-color: $input-color !default;
$form-file-bg: $input-bg !default;
$form-file-border-width: $input-border-width !default;
$form-file-border-color: $input-border-color !default;
$form-file-border-radius: $input-border-radius !default;
$form-file-box-shadow: $input-box-shadow !default;
$form-file-button-color: $form-file-color !default;
$form-file-button-bg: $input-group-addon-bg !default;

$form-file-padding-y-sm: $input-padding-y-sm !default;
$form-file-padding-x-sm: $input-padding-x-sm !default;
$form-file-font-size-sm: $input-font-size-sm !default;
$form-file-height-sm: $input-height-sm !default;

$form-file-padding-y-lg: $input-padding-y-lg !default;
$form-file-padding-x-lg: $input-padding-x-lg !default;
$form-file-font-size-lg: $input-font-size-lg !default;
$form-file-height-lg: $input-height-lg !default;

// Form validation

$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $form-text-font-size !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: #66d432 !default;
$form-feedback-invalid-color: #fd5c70 !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
// scss-docs-end zindex-stack

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: null !default;
$nav-link-hover-color: null !default;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;
$nav-link-footer-padding: 0.25rem !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius-md !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: 0.75rem !default;
$nav-pills-link-active-color: $dark !default;
$nav-pills-link-active-bg: $white !default;
$nav-pills-link-transition: background-color 0.3s ease !default;
$nav-pills-background: $gray-100 !default;
$nav-pills-vertical-background: transparent !default;
$nav-pills-vertical-radius: 1.1875rem !default;
$nav-pills-vertical-link-radius: 0.875rem !default;

// Navbar

$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: null !default;
$navbar-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16) !default;

$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-nav-link-padding: $navbar-nav-link-padding-x 1rem !default;

$navbar-brand-font-size: $font-size-sm !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;
$navbar-brand-margin-right: 1rem !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;

$navbar-blur-bg-color: rgba(255, 255, 255, 0.8) !default;
$navbar-blur-dark-bg-color: rgba(2, 5, 22, 0.8) !default;

$navbar-dark-color: rgba($white, 0.85) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: $dark !default;
$navbar-light-hover-color: rgba($dark, 0.7) !default;
$navbar-light-active-color: rgba($dark, 0.9) !default;
$navbar-light-disabled-color: rgba($dark, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($dark, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// Sidenav toggler
$sidenav-toggler-width: 18px !default;
$sidenav-toggler-line-transition: all 0.15s ease !default;
$sidenav-toggler-line-height: 2px !default;
$sidenav-toggler-line-margin-bottom: 3px !default;
$sidenav-toggler-line-active-width: 13px !default;
$sidenav-toggler-line-transform: translateX(5px) !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 11rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 1.625rem !default;
$dropdown-font-size: $font-size-sm !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: transparent !default;
$dropdown-border-radius: $border-radius-md !default;
$dropdown-border-width: 0 !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-transition-time: 0.3s ease !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer * 0.5 !default;
$dropdown-box-shadow: $box-shadow-lg !default;

$dropdown-link-color: $font-color !default;
$dropdown-link-hover-color: $h-color !default;
$dropdown-link-hover-bg: $gray-200 !default;

$dropdown-link-active-color: $font-color !default;
$dropdown-link-active-bg: transparent !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.3rem !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;

$dropdown-dark-color: $gray-300 !default;
$dropdown-dark-bg: $gray-800 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;

// Pagination

$pagination-padding-y: 0.375rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-radius: $border-radius-md !default;
$pagination-margin-left: -$pagination-border-width !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: $link-hover-color !default;
$pagination-focus-bg: $gray-200 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Cards

$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-title-spacer-y: $spacer * 0.5 !default;
$card-border-width: 0 !default;
$card-border-radius: 1rem !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y * 0.5 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: $white !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: $spacer !default;

$card-group-margin: $grid-gutter-width * 0.5 !default;

// Accordion
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1rem !default;
$accordion-color: $body-color !default;
$accordion-bg: transparent !default;
$accordion-border-width: 0 !default;
$accordion-border-color: rgba($black, 0.125) !default;
$accordion-border-radius: $border-radius-sm !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: $accordion-color !default;
$accordion-button-bg: $accordion-bg !default;
$accordion-transition: $btn-transition, border-radius 0.15s ease !default;
$accordion-button-active-bg: $accordion-bg !default;
$accordion-button-active-color: $dark !default;

$accordion-button-focus-border-color: $input-focus-border-color !default;
$accordion-button-focus-box-shadow: none !default;

$accordion-icon-width: 1rem !default;
$accordion-icon-color: $accordion-color !default;
$accordion-icon-active-color: $accordion-button-active-color !default;
$accordion-icon-transition: transform 0.2s ease-in-out !default;
$accordion-icon-transform: rotate(180deg) !default;

$accordion-button-icon: none !default;
$accordion-button-active-icon: none !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius-md !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: $spacer * 0.25 !default;
$tooltip-padding-x: $spacer * 0.5 !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Popovers

$popover-font-size: $font-size-xs !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: 0px !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: $box-shadow-sm !default;

$popover-header-bg: $gray-200 !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: $spacer !default;

$popover-body-color: $font-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Toasts

$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.75rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 0 !default;
$toast-border-color: transparent !default;
$toast-border-radius: $border-radius-md !default;
$toast-box-shadow: $box-shadow !default;

$toast-header-color: $h-color !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba(0, 0, 0, 0.05) !default;

// Badges

$badge-font-size: 0.75em !default;
$badge-font-weight: $font-weight-bolder !default;
$badge-color: $white !default;
$badge-padding-y: 0.55em !default;
$badge-padding-x: 0.9em !default;
$badge-border-radius-custom: 0.45rem !default;
$badge-border-radius: $badge-border-radius-custom !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: $spacer !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer !default;
$alert-padding-x: $spacer !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius-md !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

$alert-dismissible-padding-r: $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side

// Progress bars

$progress-height: 3px !default;
$progress-bar-height: 6px !default;
$progress-height-sm: 4px !default;
$progress-height-lg: 20px !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius-md !default;
$progress-box-shadow: $box-shadow-inset !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-color: inherit !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius-md !default;

$list-group-item-padding-y: $spacer * 0.5 !default;
$list-group-item-padding-x: $spacer !default;
$list-group-item-bg-level: -9 !default;
$list-group-item-color-level: 6 !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius-md !default;
$thumbnail-box-shadow: $box-shadow-sm !default;

// Figures

$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $gray-600 !default;

// Breadcrumbs

$breadcrumb-font-size: null !default;
$breadcrumb-padding-y: $spacer * 0.5 !default;
$breadcrumb-padding-x: $spacer !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: $gray-200 !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-border-radius: $border-radius-md !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-opacity: 0.5 !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-active-opacity: 1 !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;
$carousel-caption-padding-y: 1.25rem !default;
$carousel-caption-spacer: 1.25rem !default;

$carousel-control-icon-width: 2rem !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 16 16'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 16 16'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg: $black !default;
$carousel-dark-caption-color: $black !default;
$carousel-dark-control-icon-filter: invert(1) grayscale(100) !default;

// Spinners

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: 0.25em !default;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;

// Close

$btn-close-width: 1em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $white !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$btn-close-color}' viewBox='0 0 16 16'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow: $input-btn-focus-box-shadow !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;

// Code

$code-font-size: $small-font-size !default;
$code-color: $pink !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;
$pre-color: null !default;

// Tilt Animation

$tilt-transform-style: preserve-3d !default;
$tilt-transform-up-transform: translateZ(50px) scale(0.7) !default;
$tilt-transform-up-transition: all 0.5s !default;

// Variables for Core

@import "variables/animations";
@import "variables/avatars";
@import "variables/cards";
@import "variables/dark-version";
@import "variables/dropdowns";
@import "variables/header";
@import "variables/info-areas";
@import "variables/navbar";
@import "variables/navbar-vertical";
@import "variables/utilities";
@import "variables/utilities-extend";
@import "variables/misc";
@import "variables/misc-extend";
@import "variables/form-switch";
@import "variables/fixed-plugin";
@import "variables/pagination";
@import "variables/badge";
@import "variables/rtl";
@import "variables/cards-extend";
@import "variables/choices";
@import "variables/timeline";
@import "variables/full-calendar";
@import "variables/social-buttons";
@import "variables/virtual-reality";

// Import Utilities
@import "utilities";
