@media print {
  body {
    color: black;
    background-color: white !important;
    margin: 0;
  }

  /* Override Bootstrap's container to be full width. */
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    max-width: 100%;
  }

  /* Make all Bootstrap's columns full width. */
  .row {
    display: block;
  }

  [class*="col-"] {
    width: 100% !important;
    display: block;
    float: none !important;
  }

  /* Avoid page breaks in the content if possible. */
  .avoid-page-break {
    page-break-inside: avoid;
  }
  /* Hide elements that are unnecessary for print */
  nav.navbar,
  .sidebar,
  #breadcrumbs,
  .interactive-elements {
    display: none;
  }
}
