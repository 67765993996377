// Bootstrap markdown styles
// Add the .md-content class to the parent element of the markdown content

.md-content {
  // Overide the theme markdown styles
  h1,
  h2,
  h3,
  h4 {
    color: $dark;
  }

  h5,
  h6 {
    color: $primary;
  }

  a {
    color: $link-color;
  }

  // bootstrap table styles to table element

  // table {
  //   @extend .table;
  //   @extend .table-bordered;
  //   @extend .table-hover;
  //   @extend .table-sm;
  // }

  // Override the default Bootstrap blockquote styles
  blockquote {
    @include border-radius($border-radius-lg);
    border-left: 5px solid $primary;
    padding: 1rem 1.5rem;
    margin: 1.5rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    background-color: #fdecfd;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

// Sidebar styles
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
}

.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
}

.btn-toggle-nav li {
  padding-left: 1.125rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.scrollarea {
  overflow-y: auto;
}
